a{display:inline-flex;text-decoration: none;}
.contactCard_short{
    min-height:200px;
    min-width:300px;
    padding: 20px;
}
.tallList select{
    height:300px !important;
}
.avatar{
    color:#fff;
    display:inline-flex;
}

.reporting_table{
    min-width:400px;
    max-width:80%;
}
.pendingItem{
    background-color: #d4edda;
}
.doneItem{
}